body {
  background-color: #f5f5f5;
  font-family: "Varela Round", sans-serif;
}

body[data-url^="https://giftr"] {
  background-color: #fff !important;
}

.container-fluid {
  padding-right: 0 !important;
  padding-left: 0 !important;
}


/*Lottie*/
.lottie-col{
  text-align: center;
  margin-top: 2.5em;
}

.lottie-col img{
  width: 19em;
}

.lottie-col div {
  margin-top: 0 !important;
  height: 12em !important;
  width: 20em !important;
}

/*Login & Forgot Password*/
.login-form {
  margin-top: 90px;
  text-align: center;
  background: #fff;
  padding: 26px;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}

.login-form label {
  margin-bottom: 10px;
}

.login-form img {
  display: block;
  margin: 0 auto;
  margin-bottom: 35px;
}

.form-control:focus {
  border-color: #80d7cf;
  box-shadow: 0 0 0 0.25rem rgb(128 215 206 / 30%);
}

.login-backdrop{
  background: url("../images/blue-snow.png");
  height: 100%;
}

#gifting-made-easy{
  font-size: 16px;
  font-style: italic;
  color: #81d8d0;
  font-weight: 600;
}

/*Buttons*/
.btn-primary,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover {
  background-color: #7f35ff !important;
  border-color: #7f35ff !important;
}

.btn-primary:focus {
  box-shadow: 0 0 0 0.25rem rgb(176 131 252 / 50%);
}

.btn-outline-primary,
.btn-outline-primary:focus {
  color: #7f35ff !important;
  border-color: #7f35ff !important;
}

.btn-check:checked + .btn-outline-primary,
.btn-outline-primary:hover,
.btn-outline-primary:active,
.btn-outline-primary:focus {
  color: #fff !important;
  box-shadow: 0 0 0 0.25rem rgb(176 131 252 / 50%);
  background-color: #7f35ff !important;
}

.btn-secondary,
.btn-secondary:active,
.btn-secondary:focus,
.btn-secondary:hover {
  background-color: #fff !important;
  border-color: #b2b2b2 !important;
  color: #5a5a5a;
}

.btn-secondary:focus {
  box-shadow: 0 0 0 0.25rem rgb(178 178 178 / 50%);
}

.btn-secondary:disabled {
  background-color: #fff !important;
  border-color: #b2b2b2 !important;
  color: #b2b2b2;
}

.btn-success,
.btn-success:active,
.btn-success:focus,
.btn-success:hover {
  background-color: #4e8767 !important;
  border-color: #4e8767 !important;
  color: #fff;
}

.btn-success:focus {
  box-shadow: 0 0 0 0.25rem rgb(78 135 103 / 50%);
}

.btn-success:disabled {
  background-color: #4e8767 !important;
  border-color: #4e8767 !important;
  color: #fff;
}

.btn:disabled {
  cursor: not-allowed;
  pointer-events: unset;
}

.btn-danger,
.btn-danger:active,
.btn-danger:focus,
.btn-danger:hover {
  background-color: #d15d5d !important;
  border-color: #d15d5d !important;
  color: #fff;
}

.btn-danger-secondary,
.btn-danger-secondary:active,
.btn-danger-secondary:focus,
.btn-danger-secondary:hover {
  background-color: var(--class-item-bg) !important;
  border-color: #d15d5d !important;
  color: #d15d5d !important;
}

.btn-danger:focus {
  box-shadow: 0 0 0 0.25rem rgb(209 93 93 / 50%);
}

.btn-info,
.btn-info:active,
.btn-info:focus,
.btn-info:hover {
  background-color: #80d7cf !important;
  border: none !important;
  color: #fff !important;
}

.btn-info:focus {
  box-shadow: 0 0 0 0.25rem rgb(128 215 207 / 50%);
}

.btn-clear,
.btn-clear:active,
.btn-clear:focus,
.btn-clear:hover {
  background-color: none !important;
  border: none !important;
  color: #5A5A5A !important;
}

.btn-clear:focus, .btn-clear-danger:focus {
  box-shadow: none;
}

.btn-clear-danger {
  background-color: none !important;
  border: none !important;
  color: #d15d5d !important;
}

.btn-warning,
.btn-warning:active,
.btn-warning:focus,
.btn-warning:hover,
.btn-warning:disabled {
  color: #fff;
}

.btn-br-6 {
  border-radius: 6px;
}

.bg-primary{
  background-color: #7f35ff !important;
}

.bg-success {
  background-color: #4e8767 !important;
}

.bg-danger {
  background-color: #d15d5d !important;
}

.bg-default {
  background-color: #dee2e6 !important;
  color: #333;
}

.bg-info{
  background-color: #80d7cf !important;
  color: #fff;
}

.custom-file-upload {
  border: 0;
  padding: 0;
  width: 100%;
  background: #7f35ff;
  border-radius: 18px;
  color: #fff;
  line-height: 2em;
  text-align: center;
  font-size: 0.9em;
}

.custom-file-upload input[type="file"] {
  display: none;
}

.dropzone-area {
  border: 2px dashed #b2b2b2;
  background: #f5f5f5;
  color: #b2b2b2;
  text-align: center;
}

.dropzone-text {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1.7rem;
  position: relative;
}

.dropzone-info {
  position: absolute;
  top: 0;
  right: 0;
  margin: 8px 10px;
}

input:disabled {
  background: #fff;
}

/*Custom checkboxes & radio button*/
.custom-checkbox, .custom-radio {
  display: block;
  position: relative;
  cursor: pointer;
  user-select: none;
  padding-left: 1.5em;
  padding-right: 2em;
  min-height: auto;
  font-weight: 400;
}

.custom-checkbox input, .custom-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 1px;
  left: 0;
  background: #fff;
  width: 1.25em;
  height: 1.25em;
  border: 2px solid #ccc;
  border-radius: 5px;
}

.radio {
  position: absolute;
  top: 4px;
  left: 0;
  width: 0.85em;
  height: 0.85em;
  background-color: #fff;
  border: 2px solid #80d7cf;
  border-radius: 50%;
}

.custom-checkbox:hover input ~ .checkmark,
.custom-radio:hover input ~ .radio {
  border-color: #81d8d0;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

.custom-checkbox input:checked ~ .checkmark {
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

.checkmark:after, .radio:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-checkbox input:checked ~ .checkmark:after,
.custom-radio input:checked ~.radio:after {
  display: block;
}

.custom-checkbox .checkmark:after {
  content: "\2713";
  font-size: 1.6em;
  font-weight: 200;
  line-height: 0.8;
  color: #81d8d0;
  font-family: "Varela Round";
  border-color: #81d8d0;
}

.custom-radio .radio:after {
  left: 0.07em;
  top: 0.05em;
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  background: #80d7cf;
}

.checkmark-smaller {
  width: 0.9em;
  height: 0.9em;
  border-radius: 3px;
  border: 1.5px solid #ccc;
  top: 3px
}

.checkmark-disabled {
  border-color: #ccc !important;
  box-shadow: initial !important;
  cursor: initial !important;
}

.custom-checkbox .checkmark-smaller:after{
  font-size: 1.25em;
  font-weight: 600;
  line-height: 0.5;
}

/*Table*/
.fadeIn {
  animation: fadeIn 0.5s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    };
}

.resizer {
  display: inline-block;
  background: #81d8d0;
  opacity: 0.5;
  width: 5px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transform: translateX(50%);
  -ms-transform: translateX(50%);
  transform: translateX(50%);
  z-index: 1;
  touch-action: none;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-move {
  cursor: move;
}

th[data-row-selection="true"] {
  width: 30px;
}

.react-bootstrap-table, .table-responsive {
  overflow-x: auto;
}

.react-bootstrap-table table, table {
  table-layout: fixed;
}

.react-bootstrap-table th,
.react-bootstrap-table td {
  white-space: nowrap;
  overflow: hidden;
}

.react-bootstrap-table a , table a{
  color: #80d7cf;
  text-decoration: none;
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: #fff;
}

.react-bs-table-tool-bar {
  margin-bottom: 1rem !important;
  margin-top: 1.5rem !important;
}

.react-bs-table-tool-bar .react-bs-table-search-form{
  width: 70%;
  margin-left: auto;
}

.container-fluid .search-label{
  width: 100%
}

.old-bs-table .react-bs-table-tool-bar .row .col-xs-6{
  width: 50%;
}

.old-bs-table .react-bs-table-tool-bar .btn-group-sm > .btn {
  padding: 0.5rem 0.54rem;
  font-size: 1rem;
}

.old-bs-table thead{
  border-top: 0 !important;
}

table .caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.dropup .caret, .navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: "";
}

/*Modal & Cards*/
.addons-modal {
  max-height: 90vh;
}

.modal {
  padding-left: 0 !important;
}

.modal-content {
  border: none;
  background: #f5f5f5;
}

.tealCard {
  background-color: #fff;
  color: #000;
  border: none;
  border-radius: 6px;
}

.tealCardHeader, .tealCardHeader .accordion-button {
  background-color: #80d7cf;
  color: #fff;
  border-bottom: none;
  font-size: 1.1em;
}

.greyCard {
  background-color: #fff;
  color: #000;
  border: 2px solid #F5F5F5;
  border-radius: 6px;
}

.greyCardHeader{
  background-color: #F5F5F5;
  color: #5A5A5A;
  margin-bottom: 0;
  border-bottom: none;
}

.redCard {
  background-color: #fff;
  color: #000;
  border: 2px solid #d15d5d;
  border-radius: 6px;
}

.redCardHeader{
  background-color: #d15d5d;
  color: #fff;
  margin-bottom: 0;
  border-bottom: none;
}

.add-remark, .add-remark:hover{
  color: #d15d5d;
  font-size: 0.9em;
  text-decoration: none;
  padding: 0;
  margin-left: 10px;
}

.edit-delivery-info, .edit-delivery-info:hover{
  font-size: 0.8em;
  padding: 2px 5px;
}

.update-financial-status, .update-financial-status:hover {
  color: #fff;
  font-size: 0.9em;
  text-decoration: none;
  padding: 0;
}

.label-strong label{
  font-weight: 600;
  color: #333;
}

.grey-label label{
  color: #5A5A5A;
}

.editDeliveryModal .SingleDatePicker{
  width: 50%;
}

.editDeliveryModal .SingleDatePickerInput__calendar-icon,
.manual-order-modal .SingleDatePickerInput__clear-date {
  margin: 0;
}

.modal-shadow {
  box-shadow: 0 0 10px;
}

.confirm-modal {
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.nested-modal {
  z-index: 1060;
}

.nested-modal-backdrop {
  z-index: 1055;
}

.map-modal ~ .pac-container {
  z-index: 1065;
}

.map-container {
  height: 600px;
}

.list-group-item.active {
  background-color: #81d8d0;
  border-color: #81d8d0;
}

/*Top Navbar for mobile*/
.header, .login-header {
  background-color: #ffffff !important;
  color: #80d7cf;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%)
}

.header a, .login-header a {
  color: #80d7cf;
}

.header .nav-logo, .login-header .nav-logo {
  position: relative;
  top: 2px;
  margin: 0 20px;
  height: 2.5em;
}

.header .header-branding {
  position: relative;
  top: 0;
  margin: 0 15px 0 10px;
  height: 2em;
}

/*Side Navbar for tablet & desktop & Accordions*/
.sidebar {
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  padding: 20px;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #fff;
  box-shadow: 0.1em 0 0.5em 0 rgb(90 90 90 / 5%);
}

.nav-sidebar {
  margin-right: -21px;
  margin-bottom: 20px;
  margin-left: -20px;
}

.nav-sidebar > li > a {
  padding-right: 20px;
  padding-left: 20px;
}

.nav-sidebar .accordion-button, .header-accordion .accordion-button {
  color: #5A5A5A
}

.nav-sidebar > a.active {
  color: #80d7cf !important;
}

.sidebar::-webkit-scrollbar {
  width: 6px;
}

.sidebar::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #e6e6e6;
}

.sidebar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #d1d1d1;
}

/*Navbar Offcanvas for mobile*/
.navbar-toggler,
.navbar-toggler:focus {
  border: 0;
  color: #5a5a5a;
  box-shadow: none;
}

.offcanvas-body .link-light {
  color: #5a5a5a;
  text-decoration: none;
}

.offcanvas-end {
  width: 65%;
  border-left: 0;
}

.nav-link {
  color: #5a5a5a;
  font-size: 16px;
}

.nav-link:focus,
.nav-link:active,
.nav-link:hover,
.offcanvas-body a.active {
  color: #80d7cf;
}


.nav-accordion .accordion-body{
  padding: 0.2rem 1.25rem;
}

.nav-accordion .accordion-button:focus,
.taggingsCard .accordion-button:focus {
  border: none;
  box-shadow: none;
}

.nav-accordion .accordion-button:not(.collapsed) {
  background-color: #fff;
  box-shadow: none;
}

.nav-accordion .accordion-button{
  padding: 0.5rem 1rem;
}

.nav-accordion .accordion-button .active, .nav-accordion .accordion-body .active{
  color: #80d7cf !important;
}

.nav-accordion .accordion-button::after{
  width: 0.9rem;
  height: 0.9rem;
  background-size: 0.9rem;
}

.header-accordion .accordion-button{
  padding: 0 !important;
}

.header-accordion .accordion-body{
  padding: 0.2rem 1.5rem !important;
}

.logout-btn{
  color: #D15D5D !important
}

.notify-badge {
  background: red;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 12px;
  display: inline-block;
  text-align: center;
}

.info-badge {
  background: #5a5a5a;
  color: white;
  border-radius: 5px;
  height: 20px;
  font-size: 12px;
  display: inline-block;
  text-align: center;
}

/* Main content*/
.main {
  padding: 85px 20px 20px 20px;
  width: 100%;
}

.overlay-loader {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 10000;
  justify-content: center;
  display: flex;
  align-items: center;
}

/* Quick Help */
@media (max-width: 576px) {
  .header-btns {
    flex-direction: row !important;
  }
}

.header-btns:empty {
  gap: unset !important;
}

.help-option {
  cursor: pointer
}

.help-option:hover {
  background-color: #81d8d0;
  color: white;
}

/*Alerts*/
.sticky-alert {
  position: sticky;
  position: -webkit-sticky;
  top: 60px;
  z-index: 1;
}

/*Dashboard*/
.dashboard-vr {
  color: #707070;
  padding: 0;
  margin: 0 2%;
}

.dashboard-card {
  color: #000;
  border-radius: 18px;
  border: 0;
}

.dashboard-small-text {
  text-align: center;
  font-size: 0.85em;
}

.dashboard-sales-label {
  font-weight: 600;
  font-size: 1.1em;
}

.dashboard-sales-period-label,
.dashboard-orders-label {
  color: #80d7cf;
  font-size: 0.9em;
}

.dashboard-number-text {
  font-size: 1.6em;
  line-height: 2em;
}

.dashboard-todo-label {
  line-height: 1em;
  margin: 0.4rem 0;
}

.dashboard-todo-link {
  font-weight: 600;
}

.dashboard-today-unfulfilled {
  margin-top: 0.4rem;
  font-weight: normal;
  color: #000;
}

/*Orders*/
.dashboard-card .accordion-button:not(.collapsed),
.dashboard-card .accordion-button.collapsed,
.dashboard-card .accordion-item:last-of-type .accordion-button.collapsed {
  color: #000;
  background-color: #fff;
  box-shadow: none;
  border-radius: 18px;
  border: none;
}

.dashboard-card .accordion-button:not(.collapsed)::after,
.nav-accordion .accordion-button:not(.collapsed)::after{
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.taggingsCard .accordion-button:not(.collapsed)::after,
.taggingsCard .accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.dashboard-card .accordion-button:focus {
  border-color: transparent;
  box-shadow: none;
}

.admin-prompt{
  color: #5A5A5A;
  text-align: center;
}

.admin-prompt img{
  margin: 40px 0;
  width: 50%;
}

.filter-buttons {
  height: unset;
}

.search-order-btn{
  width: 50%;
}

.page-item.active .page-link {
  background-color: #80d7cf;
  color: #fff;
  border: none;
}

.page-link {
  color: #5a5a5a;
}

.page-link:focus {
  box-shadow: 0 0 0 0.25rem rgb(128 215 207 / 25%);
}

.page-link:hover {
  box-shadow: 0 0 0 0.25rem rgb(128 215 207 / 25%);
  background-color: #fff;
  border: none;
  color: #80d7cf;
}

.ship-by-alert{
  color: #ff0000;
}

/*Order Detail*/
.order-list-items {
  display: grid;
}

.overflowX-auto {
  overflow-x: auto
}

.order-detail-item table td{
  padding-top: 20px !important;
}

.line-item-detail a, .line-item a, .line-item-detail .item-title {
  color: #7f35ff;
}

.line-item-detail a:hover, .line-item a:hover{
  color: #80d7cf;
}

.order-detail-item .table > :not(caption) > * > * {
  border-bottom: 0.5px solid #dee2e6;
}

.order-detail-item-desc {
  white-space: pre-line;
}

.line-item-detail{
  width: 400px;
  white-space: normal !important;
}

.fulfillments a {
  text-decoration: none;
}

.shipping-lines, .markup-total {
  color: #8c8c8c;
  font-weight: 400;
  font-size: smaller;
}

.delivery-info-value {
  white-space: pre-line;
}

.btn-toolbar .btn {
  width: 100%
}

.badge-quantity {
  top: -2px;
  right: -2px;
  position: absolute;
  background: #80d7cf;
  color: #fff;
  border-radius: 50%;
  line-height: 1.2em;
}

.copy-to-clipboard{
  border-radius: 6px;
  font-size: smaller
}

.line-item-image{
  text-align: center;
  position: relative;
}

.line-item-image img {
  max-width: 100%;
  width: auto;
  height: auto;
}

.line-item-row-image{
  text-align: left;
  position: relative;
}

.line-item-row-image img {
  max-width: 80px;
  width: auto;
  height: auto;
}

.print-vm-button {
  background-color: #ff9b07;
  border-color: #ff9b07;
}

.print-vm-button:hover, .print-vm-button:focus {
  background-color: #ffae37;
  border-color: #ffae37;
}

.shipping-active {
  background-color: #80d7cf !important;
  border: none !important;
  color: #fff !important;
}

.whatsapp-preview-box {
  max-width: 560px;
  margin: 0 auto;
  padding: 6px 7px 8px 9px;
  border-radius: 7.5px;
  background-color: #d9fdd3;
  font-family: Segoe UI, Helvetica Neue, Helvetica, Lucida Grande, Arial, Ubuntu, Cantarell, Fira Sans, sans-serif;
  font-size: 14px;
  box-shadow: 0 1px .5px #D1CFC8;
  white-space: pre-line;
  position: relative;
}

.whatsapp-preview-tail {
  position: absolute;
  top: 0;
  z-index: 100;
  display: block;
  width: 8px;
  height: 13px;
  right: -8px;
  color: #d9fdd3;
}

.white-space-pre-line {
  white-space: pre-line !important;
}

.white-space-no-wrap {
  white-space: nowrap !important;
}

.tracking-info-modal-dialog {
  margin: 150px auto;
}

.faded {
  color: #666 !important;
}

.faded:hover {
  background-color: #fff !important;
  cursor: unset;
}

.border-select {
  border-color: #81d8d0 !important;
  border-width: 2px !important;
}

/*Print message card*/
.preview-message-content {
  padding: 100px;
  font-size: 20px;
}

.square-message {
  aspect-ratio: 1/1;
  width: 50%;
  margin: auto;
}
.square-message .preview-message-content {
  padding: 50px;
  font-size: 1rem;
}

@media print {
  .preview-message-content {
    padding: 1.5rem !important;
    font-size: unset !important;
  }
  .square-message {
    aspect-ratio: unset;
    width: unset;
  }
}

/*Date picker*/
.DateRangePickerInput,
.SingleDatePickerInput {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  color: white;
  font-size: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.manual-order-modal .SingleDatePicker {
  width: 100%;
}

.DateInput {
  font-size: 0.97em;
}

.DateInput input {
  text-align: center;
}

.SingleDatePickerInput input {
  text-align: start;
}

.DateInput__display-text {
  padding: 0;
}

.DateRangePickerInput_arrow {
  content: url("../images/dash-icon.svg");
  width: 3%;
}

.DateRangePickerInput_calendarIcon {
  content: url("../images/calendar-icon.svg");
  margin: 0;
}

.SingleDatePickerInput_calendarIcon {
  content: url("../images/calendar-icon.svg");
  margin: 0;
}

.DateRangePickerInput__arrow svg {
  display: none;
}

.CalendarDay {
  border: none;
  font-size: 1em;
}

.CalendarDay__hovered_span,
.CalendarDay__selected_span {
  background: rgb(128 215 207 / 50%);
  border-radius: 50%;
  color: #fff;
  border: none;
}

.CalendarDay__default {
  border-radius: 50%;
  border: none;
}

.CalendarDay__selected_span.CalendarDay__hovered,
.CalendarDay__hovered_span:active,
.CalendarDay__hovered,
.CalendarDay__selected_span:active,
.CalendarDay__selected_start,
.CalendarDay__selected_end,
.CalendarDay__selected_span:hover,
.CalendarDay__selected:hover,
.CalendarDay__default:hover,
.CalendarDay__selected {
  background: #80d7cf;
  color: #fff;
  border-radius: 50%;
  border: none;
}

.DateInput_input__focused {
  background: #fff;
  border: none;
  color: #80d7cf;
}

/* .DayPickerKeyboardShortcuts_show__bottomRight::before { */
/*   border-right: 33px solid #80d7cf; */
/* } */

.DayPickerKeyboardShortcuts_show__bottomRight {
  display: none;
}

.DayPicker__hidden{
  visibility: unset;
}

.calendar-fixed-height .DayPicker_transitionContainer{
  height: 338px !important;
}

.preview-delivery-dates .CalendarDay__blocked_out_of_range {
  color: #484848;
}

.preview-delivery-dates .CalendarDay__default {
  border-radius: 4px;
}

.preview-delivery-dates .CalendarDay__default:hover {
  color: #484848;
  background: #eee;
}

.preview-delivery-dates .CalendarDay__highlighted_calendar {
  background: #D3D3D3 !important;
}

/*Products*/
.old-bs-table td {
  vertical-align: middle !important;
  overflow: hidden;
}

/*Product Create - Product Detail, Pending Products, Create Product*/
.product-detail-image {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.product-detail-image img {
  max-width: 100%;
  margin: 5px 0;
  max-height: 90%;
}

.product-detail-image:hover div {
  visibility: visible !important;
}

.__react_component_tooltip{
  font-weight: 400;
}

.select-image {
  height: 100%;
  width: 100%;
  background: none !important;
  border-radius: 3px;
}

.imageCheck{
  position: absolute;
  z-index: 1000;
  top: 30%;
  left: 38%;
}

.product-info dd {
  margin-bottom: 3px;
  margin-left: 120px;
}

.product-info dt {
  width: 110px;
}

.product-info {
  position: relative;
  margin: 0;
  padding: 0;
}

.variant-option {
  width: 165px;
  min-width: 165px;
}

#delivery-included-header {
  text-align: left !important;
}

.DraftEditor-root {
  width: 100%
}

.public-DraftEditor-content {
  border: solid thin white;
}

.schedule-draft .DateInput {
  width: 120px;
}

.product-toast * {
  margin: 0;
}

/*Product Attributes*/
.fields-header button {
  margin: -4px 2px 0;
  outline: none !important;
}

.product-accordion .accordion-button {
  padding: .5rem 1rem;
  font-size: 1.0em;
}

.product-accordion .accordion-button:focus {
  border-color: unset;
  box-shadow: none;
}

.product-accordion .accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.product-accordion.customisation .accordion-button::after {
  margin-left: unset !important;
}

.b2b-fine-prints {
  min-height: 80px;
  background-color: #e9ecef;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

/*Product Taggings*/
.taggingsCard .accordion-button,
.taggingsCard .accordion-button:not(.collapsed) {
  padding: 0.8rem 1rem;
  box-shadow: none;
}

.taggingsCard .accordion-header, .taggingsCard .accordion-body {
  border-radius: 18px;
}

.tagging-item-row{
  border-bottom: 1px solid #dee2e6;
}

/*Promotions*/
.sticky-summary {
  position: sticky;
  top: 60px;
}

.sticky-voucher{
  content: url("../images/couponAsset.svg");
  position: relative;
  max-width: 450px;
}

.voucher-content{
  position: absolute;
  top: 23%;
  left: 28%;
  font-size: 0.95em;
  max-width: 13em;
}

.voucher-content ul {
  padding-left: 0;
}

/*Tickets - Q&A */
.tickets-table table tr, .reviews-table table tr {
  cursor: pointer;
}

.ticket-expanded {
  height: 500px;
  overflow-y: scroll;
}

.vendor-speech-bubble {
  white-space: pre-line;
  overflow-wrap: break-word;
  position: relative;
}

.vendor-speech-bubble a {
  color: white;
  text-decoration: underline;
}

.buyer-speech-bubble {
  white-space: pre-line;
  overflow-wrap: break-word;
  position: relative;
}

.vendor-speech-bubble:after{
  content: ' ';
	position: absolute;
	width: 0;
	height: 0;
  right: -36px;
  top: 0;
	border: 12px solid;
	border-color: #80d7cf transparent transparent #80d7cf   ;
}

.buyer-speech-bubble:after{
  content: ' ';
	position: absolute;
	width: 0;
	height: 0;
  left: -36px;
  top: 0;
	border: 12px solid;
	border-color: #fff #fff transparent transparent;
}

/*Merchants*/
.merchants-table table th {
  white-space: unset !important;
}

.merchant-modal .merchant-modal-header{
  display: block !important;
}

.merchant-modal-header .btn-close{
  margin-left: 0;
}

.merchant-modal-header select{
  width: 10em !important;
}

select {
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") no-repeat 92%;
  background-size: 1em;
}

.tealCard select {
  background-position: 96%;
}

/*Bill Detail*/
.bill-detail-line-items .react-bs-table-tool-bar .row{
  flex-direction: row-reverse
}

.bill-detail-line-items .react-bs-table-tool-bar {
  margin: 1em 0 !important;
}

.bill-detail-line-items .react-bs-table-tool-bar .row .col-xs-6{
  text-align: right !important
}

.bill-detail-line-items .adjustment-btn{
  margin: 1em 0;
  padding: 0.3em 1em;
  font-size: 0.9em;
}


/*Reviews*/
.reviews-table table tr .reset-expansion-style {
  cursor: default;
  padding: 1em 0.8em;
}

.review-body {
  white-space: pre-line;
  overflow-wrap: break-word;
}

.review-image {
  height: 150px;
  margin-right: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.reply-body {
  background: #fff;
  padding: 1em;
}

/*Store Profile*/
.desc-input {
  width: 100%;
  border: 1px solid #dee2e6;
  border-radius: 3px;
  height: 100%;
}

/*Product Preview*/
.preview-container {
  margin-right: auto;
  margin-left: auto;
  font-family: "Varela Round", Arial, Helvetica, sans-serif;
  background: #fff;
}

.inner-preview {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

#product-image {
  margin-bottom: 25px;
}

.product-image-inner {
  margin: 0 20px;
  padding: 0;
  position: relative;
}

.featured-image {
  display: block;
  padding: 0px;
}

.more-view-image {
  margin: 0;
  overflow: hidden;
  padding: 0;
}

.gallery-container {
  display: flex;
  overflow-x: visible;
  margin: 15px 0px 0;
}

.dot-container {
  display: block;
  position: relative;
}

.dot-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.image-dot {
  position: absolute;
  top: 0;
  left: 0;
  height: 12px;
  width: 12px;
  background-color: #eee;
  border-radius: 50%;
}

.dot-container:hover input ~ .image-dot {
  border: 2px solid #80d7cf;
  background-color: white;
  cursor: pointer;
}

.dot-container input:checked ~ .image-dot {
  border: 2px solid #80d7cf;
  background-color: white;
}

.image-dot:after {
  content: "";
  position: absolute;
  display: none;
}

.dot-container input:checked ~ .image-dot:after {
  display: block;
}

.dot-container .image-dot:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.vendorname {
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
  padding: 5px;
  color: #80d7cf;
}

.icon-tag {
  width: 48px;
  height: auto;
  margin-right: 5px;
}

.icon-tag-wrapper {
  max-width: 550px;
  margin-top: 15px;
  margin-bottom: 15px;
  color: #777;
}

.product-price {
  font-size: 30px;
  text-align: left;
  line-height: 1.2;
  color: #80d7cf;
}

.group-cw {
  position: relative;
  display: block;
  width: 100%;
  margin: 0px;
  overflow: hidden;
}

.selector-wrapper {
  margin-bottom: 15px;
  overflow: hidden;
  color: #777;
}

.preview-select {
  font: 14px/1.35 "Varela Round", Arial, Helvetica, sans-serif;
  border: none;
  box-shadow: inset 0 0 #fff;
  background: #f5f5f5;
  text-transform: none;
  margin: 0;
  background-repeat: no-repeat;
  vertical-align: baseline;
  min-width: 170px;
  height: 42px;
  padding: 8px 10px;
  line-height: 42px;
  color: #777;
}

.preview-select:focus,
.message-on-card:focus,
.preview-form-control:focus {
  box-shadow: 0 0 2px #81d8d0;
  outline: none;
  background: none;
}

.preview-label {
  color: #777;
  margin-top: 10px;
  margin-bottom: 0px;
  display: block;
  margin-right: 5px;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 3px;
}

.product-extra-fields-label {
  color: #777;
  margin-top: 10px;
  margin-bottom: 0px;
  display: block;
  margin-right: 5px;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 3px;
}

.message-on-card {
  width: 320px;
  height: 65px;
  font: 14px/1.35 "Varela Round", Arial, Helvetica, sans-serif;
  box-shadow: inset 0 0 #fff;
  background-repeat: no-repeat;
  border: none;
  padding-left: 15px;
  padding-right: 15px;
  background: #f5f5f5;
  color: #777;
  margin: 0;
  padding: 0.615384615em;
  overflow: auto;
  vertical-align: top;
}

.preview-form-control {
  width: 100%;
  padding: 7px 10px;
  line-height: 1.5;
  height: 42px;
  background-repeat: no-repeat;
  border: none;
  font-size: 14px;
  box-shadow: inset 0 0 #fff;
  background: #f5f5f5;
  margin: 0;
  display: block;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.additional-customization {
  max-width: 100%;
}

.message-char-count {
  margin: 0.6em 0 0 0;
  color: #777;
}

/*Preview Checkbox*/
.filedrop {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.drop-a-hint {
  width: 60%;
}

.inventory-modal-info {
  width: 400px;
}

.product-desc-reviews {
  font-size: 30px;
  font-weight: 200;
  margin: 40px 0;
  padding: 0 0 20px;
  border-bottom: 1px solid #eee;
}

.desc-items img {
  max-width: 100%;
  height: auto;
  border: 0;
}
.add-ons-info {
  margin-top: 10px;
  padding: 5px 10px;
  border: 1px solid #81d8d0;
  display: inline-block;
}

.quantity {
  display: inline;
  margin-bottom: 0px;
  margin-right: 20px;
}

.quantity img {
  width: 5%;
  display: inline-block;
}

.quantity img:last-child {
  margin-right: 10px;
}

.action-button {
  display: inline-block;
  padding: 0;
}

#add-to-cart {
  background: #f37022;
  border-color: #f37022;
  min-width: 230px;
  margin-bottom: 5px;
  font-size: 17px;
  letter-spacing: -0.04em;
  display: inline-block;
  font-family: "Varela Round", sans-serif;
  font-weight: 400;
  color: white;
  padding: 10px 25px;
  line-height: 20px;
  border-radius: 0 !important;
}

/*Announcements*/
.timeline-button {
  margin-left: 1em;
}

/*New Announcements*/
.announcement {
  background: #bf0000;
  color: #fff;
}

.announcement-text {
  align-self: center;
  font-size: 0.9em;
}

.announcement-hr {
  margin: 0;
}

.announcement .close {
  color: #000;
  text-shadow: 0 1px 0 #fff;
  font-size: 21px;
  font-weight: 700;
  opacity: .2;
}

.announcement a {
  color: #fff;
}

.announcement a:hover {
  color: red;
}

.myloader.v-center {
  margin: auto;
  position: absolute;
  height: 24px;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/*Shipping Modal*/
.shipping-service-image {
  height: 50px;
}

.shipping-schedule-at .DateInput {
  width: 125px !important;
}

.shipping-schedule-at .rc-time-picker-input {
  padding: 8px 11px !important;
  font-size: 15px;
  line-height: unset;
  color: #484848;
  padding: 11px 11px 9px;
  height: unset;
  width: 125px;
}

.shipping-schedule-at .DateInput_input {
  padding: 8px 11px;
  font-size: 15px;
  line-height: unset;
}

.shipping-schedule-at .DateInput_input__focused {
  color: #484848;
}

.rc-time-picker-panel-inner {
  transform: translateY(-45px);
}

/*Order Tracking*/
.order-tracking{
  background: #fff;
}

.track-order-line-item {
  margin-top: 10px;
}

.highlight {
  background-color: yellow;
  font-weight: bold;
}

.tracking-section {
  border: solid #81d8d0 1px;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 25px;
}

.tracking-events-container {
  border-radius: 18px;
  margin: 5px 10px 0;
  border: solid 1px #ddd;
}

/*Product Variant*/
.product-featured-media {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.product-featured-media img {
  margin: 0 auto;
  height: 350px;
}

.product-variant-item {
  height: 70px;
  width: 70px;
}

.product-variant-item img {
  width: 100%;
}

/*Inventory Modal*/
.inventory-modal-info dl {
  margin-bottom: 0;
}

.inventory-modal-info dt {
  text-align: left;
}

/*Order Message*/
.page-break {
  page-break-after: always;
}

/* Bulk Edit */
.alt-type-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.alt-type-badge {
  margin: 5px 10px 5px 0px;
}

.variant-title {
  margin-left: 15px;
}

.variant-disabled {
  color: gray;
}

.justify-start {
  margin-right: auto;
}

.toggle-text {
  color: #5a5a5a !important;
}
/*Responsive media queries*/
/*Mobile*/
@media (max-width: 576px) {
  h2 {
    font-size: calc(1.7rem + 0.9vw);
  }
  .header, .login-header {
    display: block !important;
    padding: 0.25em 0 !important;
  }
  .sidebar {
    display: none !important;
  }
  .vendor-selection {
    margin-top: 10px;
    text-align: left;
  }
  .dashboard-card .vendor-selection {
    margin-top: unset;
  }
}

@media (min-width: 576px) {
  .vendor-selection {
    text-align: right;
  }
}

@media (max-width: 768px) {
  .store-link-btns {
    grid-template-columns: repeat(auto-fill, minmax(150px, max-content)) !important;
  }
}

/*Tablets*/
@media (min-width: 768px) {
  .container {
    width: 760px;
  }
  .main {
    padding-right: 40px;
    padding-left: 40px;
    padding-top: 20px;
    width: 81%;
  }
  .overlay-loader {
    width: 81%;
    left: 19%;
  }
  .header{
    width: 79.5% !important;
    margin-left: auto;
    box-shadow: none
  }
  .announcement{
    width: 79.5vw !important;
  }
  .sidebar {
    width: 18%;
  }
  .dashboard-card .vendor-selection {
    text-align: left;
  }
  .btn-toolbar .btn {
    width: unset
  }
  .line-item-detail{
    width: unset;
  }
  .voucher-content {
    font-size: unset;
  }
  .custom-radio .radio:after {
    left: 0.06em;
    top: 0.065em;
  }
}

/*Laptops & desktops*/
@media (min-width: 992px) {
  .login-backdrop{
    height: 100vh !important;
  }
  .container {
    width: 980px;
  }
  .desktop-login{
    width: 70%;
  }
  .header{
    width: 85% !important;
  }
  .login-header, .header{
    padding: unset !important;
  }
  .announcement{
    width: 85vw !important;
  }
  .main {
    width: 85%;
  }
  .overlay-loader {
    width: 85%;
    left: 15%
  }
  .lottie-col{
    text-align: left;
    margin-top: 0;
  }
  .lottie-col img{
    position: relative;
    left: 18%;
    top: 15%;
    width: 23em;
  }
  .lottie-col div{
    margin-top: 4em !important;
    margin-left: 5em !important;
    height: 21em !important;
    width: 24em !important;
  }
  .sidebar {
    width: 15%;
  }
  .dashboard-card .vendor-selection {
    text-align: left;
  }
  .dashboard-vr {
    margin: 0 2%;
  }
  .dashboard-small-text {
    text-align: left;
  }
  .filter-buttons {
    height: 0%;
  }
  .search-order-btn{
    width: unset;
  }
  .line-item-detail {
    white-space: nowrap !important;
  }
  .fulfillments .btn-toolbar{
    float: right;
  }
  .admin-prompt img{
    width: 20%;
  }
  .hasMultipleVariants{
    width: 35%
  }
  .voucher-content {
    left: 19%;
  }
  .old-bs-table .react-bs-table-tool-bar .btn-group-sm > .btn {
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
  }
  .additional-customization {
    max-width: 50%;
  }
  .inner-preview {
    width: 90%;
  }
  .quantity img {
    width: 3%;
  }
  .quantity img:last-child {
    margin-right: unset
  }
  #add-to-cart{
    min-width: 250px;
  }
  .drop-a-hint {
    width: 30%;
  }
  .custom-radio .radio:after {
    left: 0.05em;
    top: 0.05em;
  }
}

/*Large laptops & desktops*/
@media (min-width: 1200px) {
  .sidebar {
    width: 13%;
  }
  .container {
    width: 1200px;
  }
  .lottie-col img{
    position: relative;
    left: 17%;
    top: 15%;
    width: 27em;
  }
  .lottie-col div{
    margin-left: 9em !important;
  }
  .header{
    width: 87% !important;
  }
  .announcement{
    width: 87vw !important;
  }
  .main {
    width: 87%;
  }
  .overlay-loader {
    width: 87%;
    left: 13%;
  }
  .col-xl-4-half {
    flex: 0 0 auto;
    width: 29.165%;
    min-width: 300px;
  }
  .voucher-content {
    left: 32%;
  }
  .ticket-expanded{
    width: 79.5vw !important;
  }
  .custom-radio .radio:after {
    left: 0.07em;
    top: 0.07em;
  }
}
